import { utils } from '@ehi/global-marketing-interface';
import { i18n } from './i18n';

/**
 * This URL util is to get current page hash
 *
 * @return {string|null} the page hash or null if there is no hash
 */
export const getCurrentPageHash = () => {
  const { hash } = window.location;
  // If there is no `#/` return
  if (hash.indexOf('#/') === -1) {
    return null;
  }
  return hash.replace('#/', '/');
};

/**
 * This URL util is used to pull the route path from the `window.location.hash`
 * if one exists (so really for SPA pages). It will theneither use that to get
 * a translated page title OR transform that into a pretty string for display.
 *
 * @return {string|null} the pretty formatted (SPA) page's title or null if not a SPA page...
 */
export const getCurrentSpaPageTitle = () => {
  const { hash } = window.location;

  // If there is no `#/` then the current page is not a SPA page and the hash is just a normal
  // page anchor so get out.
  if (hash.indexOf('#/') === -1) {
    return null;
  }

  // Since the current page _does_ follow the spa pattern, remove the `#/` and `/xyz`
  // from path that contains parameters `#/PAGE_TITLE/xyz` and get the page's title!
  return i18n(`spa_page_title_${hash.replace(/(#\/)|(\/.*)/g, '')}`);
};

// Get the current page's path from the AEM object.
const pagePath = () => utils.get(window, 'ehi.aem.page_info.page_path');

/**
 * This function uses page path (provided by AEM) if available other wise falls back to using the
 * the current page's URL (from the browser) and returns the path (excluding the origin)
 *
 * The indented use case for this function is to get the path for the current page without the origin
 *
 * return {string} The current page's path
 */
export const getCurrentPagePath = () => (pagePath() ? pagePath() : document.location.pathname);

/**
 * This function uses page path (provided by AEM) if available other wise falls back to using the
 * the current page's URL (from the browser) and returns the model.json path (excluding the origin)
 * with the search intact!
 *
 * The indented use case for this function is to alter the model.json path (adding the query string)
 * for use/consumption by Adobe's code (through)`ModelManager.initialize`. For additional context
 * @see `main.js`
 *
 * return {string} The model.json path for the current with the query string
 */
export const getModelJsonPathWithQueryString = () =>
  `${pagePath() ? `${pagePath()}.model.json` : document.location.pathname.replace('.html', '.model.json')}${
    document.location.search
  }`;

/**
 * This function uses page path (provided by AEM) if available other wise falls back to using the
 * the current page's URL (from the browser) and returns the model.json path (including the origin).
 *
 * The indented use case for this function is to alter the current window path and adding model.json
 * in order to get the complete model.json path.
 *
 * return {string} The model.json path for the current page.
 */
export const getModelJsonPath = () =>
  `${
    pagePath() ? `${window.location.origin}${pagePath()}.model.json` : window.location.href.split('.html')[0]
  }.model.json`;

/**
 * A basic util to check a path against the current page's `window.location.pathname`
 *
 * @param {string} path - the path to compair aginst
 *
 * @return {boolean}
 */
export const isPathCurrentUrl = (path) => window.location.pathname === path;

/**
 * This util is intended to help build URL strings. The basic idea is that you can pass in a base URL , hash
 * andy query string (or some combination) and this util will output the correctly formatted URL.
 *
 * @param {object} urlParts - An object of url parts. Note, none of them are required
 * @param {string} urlParts.base - The starting url to add to
 * @param {string} urlParts.hash - the value that comes after the hash (`#`)
 * @param {string} urlParts.query - the value that comes after (or appends to) the query string (`?`)
 *
 * @return {string} a formatted URL. Please note if no base is set the curent URL is used with the
 *                  hash and query string!
 */
export const createUrl = ({ base, hash, query }) => {
  let url = base ? base.trim() : window.location.href;
  let currentHash = '';

  // Accounts for exisitng hash, pulling it out. It will add the hash back in later if a `hash` is no passed in
  if (url.includes('#')) {
    const [urlBase, urlHash] = url.split('#');
    url = urlBase;
    currentHash = urlHash;
  }

  // Accounts for the query string, much like the hash mentioned above.
  if (query) {
    url = `${url}${url.includes('?') ? '&' : '?'}${query}`;
  }

  // Add the hash either back on or a new one if one is passed in (though the `hash` prop)
  if (hash) {
    url = `${url}#${hash}`;
  } else if (currentHash) {
    url = `${url}#${currentHash}`;
  }

  return url;
};

/**
 * setParams
 * This util is used to replace the parameters from url (e.g. /receipt/:invoice)
 * @param {string} config.path - Url path
 * @param {object} config.params - An object used to replace the parameters from url
 * @return {string} Replaced url
 */
export const setParams = ({ path, params }) =>
  path.match(/:.*?[^/]+/g).reduce((acc, key) => {
    const pureKey = key.replace(':', '');
    const param = params[pureKey] || key;
    return acc.replace(key, param);
  }, path);

/**
 * replaceUrlParamsInBrackets - Replace url params in brackets by values (e.g. https://api.com/image/{width}/{quality})
 * @param {string} path - Path with params
 * @param {object} options - Whatever parameter name that the url provide
 * @param {number} options.width - (Common parameters) Image width (e.g. 480)
 * @param {string} options.quality - (Common parameters) Image quality (e.g. low / medium / high)
 * @return {string} image url with params replaced
 */
export const replaceUrlParamsInBrackets = (path = '', options = {}) => {
  const pattern = /\{(.*?)\}/g;
  return (path.match(pattern) || []).reduce((acc, key) => {
    const pureKey = key.replace(pattern, '$1');
    return acc.replace(key, options[pureKey] || key);
  }, path);
};

/**
 * @deprecated - use URLSearchParams instead.
 * @name queryObject
 * @description Returns the Query String from URL in Object shape
 * @example
 * // .../page.html?test=1&foo=bar
 * const queryObject = queryObject(); // {test: 1, foo: bar}
 * @returns {object}
 */
export const queryObject = () => {
  const result = {};
  const keyValuePairs = location.search.slice(1).split('&');

  keyValuePairs?.forEach((keyValuePair) => {
    const [key, value] = keyValuePair.split('=');
    if (key) {
      result[key] = value ? decodeURIComponent(value) : '';
    }
  });

  return result;
};
